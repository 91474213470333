<template lang="pug">
b-container(fluid)
  h3.mb-3 Usuario
    b-spinner.ml-3.mb-1(variant="primary", small, v-if="loadingData")
  div
    validation-observer(v-slot="{invalid, handleSubmit}")
      b-form(@submit.prevent="handleSubmit(save)")
        b-row
          b-col.mb-3(cols="6")
            b-form-group(label="Nombre")
              validation-provider(
                name="nombre",
                rules="required|min:1",
                v-slot="{ errors }"
              )
                b-form-input(
                  placeholder="Nombre",
                  required,
                  name="name",
                  v-model="user.first_name",
                  :state="errors[0] ? false : null"
                )
                b-form-invalid-feedback {{ errors[0] }}
          b-col.mb-3(cols="6")
            b-form-group(label="Apellido")
              validation-provider(
                name="apellido",
                rules="required|min:1",
                v-slot="{ errors }"
              )
                b-form-input(
                  placeholder="Apellido",
                  required,
                  name="last_name",
                  v-model="user.last_name",
                  :state="errors[0] ? false : null"
                )
                b-form-invalid-feedback {{ errors[0] }}

          b-col.mb-3(cols="6")
            b-form-group(label="Email")
              validation-provider(
                name="email",
                rules="required|email",
                v-slot="{ errors }"
              )
                b-form-input(
                  placeholder="Email",
                  required,
                  name="email",
                  v-model="user.email",
                  :state="errors[0] ? false : null"
                )
                b-form-invalid-feedback {{ errors[0] }}

          b-col.mb-3(cols="6")
            b-form-group(label="Rol")
              validation-provider(name="rol", rules="required", v-slot="{ errors }")
                b-form-select(:options="roles", v-model="user.role", :state="errors[0] ? false : null", required)
                  template(#first)
                    b-form-select-option(:value="null", disabled) -- Seleccionar rol --

          b-col.mb-3(cols="6")
            b-form-group(label="Status de sesión")
              h3
                b-badge(:variant="getStatusVariant(user.status)", pill) {{ user.status || 'Sin status' }}

          b-col.mb-3(cols="12")
            b-button.mb-3(
              v-b-toggle.passwordCollapse,
              variant="link",
              @click="newPassword = ''"
            ) 
              span Generar nueva contraseña
              chevron-right-icon.ml-2.icon-sm
            b-collapse#passwordCollapse
              b-card.no-shadow.bg-light
                b-form-row.align-items-end.mb-3
                  b-col
                    b-form-group(
                      label="Nueva contraseña",
                      description="Si NO deseas establecer una nueva contraseña deja en blanco este campo."
                    )
                      validation-provider(
                        name="contraseña",
                        :rules="{required: !user.usuario_id, min: 1}",
                        v-slot="{ errors }"
                      )
                        b-form-input(
                          placeholder="Contraseña",
                          name="password",
                          v-model="newPassword",
                          :state="errors[0] ? false : null"
                        )
                        b-form-invalid-feedback {{ errors[0] }}
                  b-col(cols="auto")
                    b-button.mb-4(variant="primary", @click="generatePassword") 
                      shield-icon.icon-sm.mr-1
                      span Random
                .text-right
                  b-link.text-danger.text-sm(
                    @click="newPassword = ''",
                    v-b-toggle.passwordCollapse
                  ) Cancelar cambio de contraseña

          b-col.mb-3(cols="12")
            b-button(
              block,
              variant="primary",
              :disabled="invalid",
              type="submit"
            ) Guardar cambios
            .mt-3.text-right
              b-button.text-danger(variant="link", @click="deleteUser") Eliminar usuario
</template>

<script>
import { createNamespacedHelpers, mapActions } from "vuex";
const usuarioController = createNamespacedHelpers("usuario");

export default {
  data() {
    return {
      user: {
        email: '',
        first_name: '',
        last_name: '',
        role: null
      },
      newPassword: "",
      roles: [
        {
          value: 'admin',
          text: 'Admin'
        },
        {
          value: 'guardia',
          text: 'Guardia'
        },
        // {
        //   value: 'monitor',
        //   text: 'Monitorista'
        // }
      ]
    };
  },
  methods: {
    ...mapActions(["usuario/get", "usuario/update", "usuario/destroy", "usuario/create"]),
    getStatusVariant(status) {
      switch (status) {
        case "entrada":
          return "success";
        case "salida":
          return "secondary";
        default:
          return "light";
      }
    },
    getUsuario() {
      this.loadingData = true;
      this["usuario/get"]({
        usuario_id: this.$route.params.usuario_id,
        callback: (res) => {
          this.loadingData = false;

          if (res.success) {
            this.user = res.resource;
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo el usuario. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    generatePassword() {
      var length = 12,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }

      this.newPassword = retVal;
    },
    save() {
      this.loadingData = true;
      let user = {};
      let params = ["first_name", "last_name", "email", 'role'];

      for (let param of params) {
        user[param] = this.user[param];
      }

      if (this.newPassword.length) {
        user.password = this.newPassword;
      }

      if (this.user.usuario_id) {
        this["usuario/update"]({
          usuario_id: this.$route.params.usuario_id,
          ...user,
          callback: (res) => {
            this.loadingData = false;

            if (res.success) {
              this.user = res.resource;
              this.$bvToast.toast(`Usuario actualizado con éxito.`, {
                title: "Actualizado",
                variant: "success",
              });
            } else {
              this.$bvToast.toast(
                `Ocurrió un error actualizando el usuario. Por favor intenta de nuevo.`,
                {
                  title: "Ocurrió un error",
                  variant: "danger",
                }
              );
            }
          },
        });
      } else {
        this["usuario/create"]({
          ...user,
          callback: (res) => {
            console.log(res);
            this.loadingData = false;

            if (res.success) {
              this.$bvToast.toast(`Usuario creado con éxito.`, {
                title: "Creado",
                variant: "success",
              });

              setTimeout(() => {
                this.$router.push({name: 'Usuarios'})
              }, 1500)
            } else {
              this.$bvToast.toast(
                `Ocurrió un error creando el usuario. Por favor intenta de nuevo.`,
                {
                  title: "Ocurrió un error",
                  variant: "danger",
                }
              );
            }
          },
        });
      }
    },
    deleteUser() {
      this.$bvModal
        .msgBoxConfirm("¿Deseas eliminar el usuario? No se puede deshacer.", {
          okVariant: 'danger',
          okTitle: 'Eliminar'
        })
        .then((value) => {
          if (value == true) {
            this.loadingData = true;
            this["usuario/destroy"]({
              usuario_id: this.$route.params.usuario_id,
              callback: (res) => {
                this.loadingData = false;
                if (res.success) {
                  this.$bvToast.toast(`Usuario eliminado con éxito.`, {
                    title: "Eliminado",
                    variant: "success",
                  });

                  setTimeout(() => {
                    this.$router.push({name: 'Usuarios'})
                  }, 1500)
                } else {
                  this.$bvToast.toast(
                    `Ocurrió un error eliminando el usuario. Por favor intenta de nuevo.`,
                    {
                      title: "Ocurrió un error",
                      variant: "danger",
                    }
                  );
                }
              },
            });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  mounted() {
    if (this.$route.params.usuario_id == 'new') {
      this.$root.$emit('bv::toggle::collapse', 'passwordCollapse')
    } else {
      this.getUsuario();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>