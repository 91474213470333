var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Usuario"),(_vm.loadingData)?_c('b-spinner',{staticClass:"ml-3 mb-1",attrs:{"variant":"primary","small":""}}):_vm._e()],1),_c('div',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nombre","required":"","name":"name","state":errors[0] ? false : null},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Apellido"}},[_c('validation-provider',{attrs:{"name":"apellido","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Apellido","required":"","name":"last_name","state":errors[0] ? false : null},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Email","required":"","name":"email","state":errors[0] ? false : null},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Rol"}},[_c('validation-provider',{attrs:{"name":"rol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.roles,"state":errors[0] ? false : null,"required":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- Seleccionar rol --")])]},proxy:true}],null,true),model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Status de sesión"}},[_c('h3',[_c('b-badge',{attrs:{"variant":_vm.getStatusVariant(_vm.user.status),"pill":""}},[_vm._v(_vm._s(_vm.user.status || 'Sin status'))])],1)])],1),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.passwordCollapse",modifiers:{"passwordCollapse":true}}],staticClass:"mb-3",attrs:{"variant":"link"},on:{"click":function($event){_vm.newPassword = ''}}},[_c('span',[_vm._v("Generar nueva contraseña")]),_c('chevron-right-icon',{staticClass:"ml-2 icon-sm"})],1),_c('b-collapse',{attrs:{"id":"passwordCollapse"}},[_c('b-card',{staticClass:"no-shadow bg-light"},[_c('b-form-row',{staticClass:"align-items-end mb-3"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Nueva contraseña","description":"Si NO deseas establecer una nueva contraseña deja en blanco este campo."}},[_c('validation-provider',{attrs:{"name":"contraseña","rules":{required: !_vm.user.usuario_id, min: 1}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Contraseña","name":"password","state":errors[0] ? false : null},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"mb-4",attrs:{"variant":"primary"},on:{"click":_vm.generatePassword}},[_c('shield-icon',{staticClass:"icon-sm mr-1"}),_c('span',[_vm._v("Random")])],1)],1)],1),_c('div',{staticClass:"text-right"},[_c('b-link',{directives:[{name:"b-toggle",rawName:"v-b-toggle.passwordCollapse",modifiers:{"passwordCollapse":true}}],staticClass:"text-danger text-sm",on:{"click":function($event){_vm.newPassword = ''}}},[_vm._v("Cancelar cambio de contraseña")])],1)],1)],1)],1),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"block":"","variant":"primary","disabled":invalid,"type":"submit"}},[_vm._v("Guardar cambios")]),_c('div',{staticClass:"mt-3 text-right"},[_c('b-button',{staticClass:"text-danger",attrs:{"variant":"link"},on:{"click":_vm.deleteUser}},[_vm._v("Eliminar usuario")])],1)],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }